import * as React from 'react'
import Layout from '../../components/help/Layout/Layout'
import Seo from '../../components/help/Seo'
import Main from '../../components/pages/Main/Main'
import { graphql, useStaticQuery } from 'gatsby'

const IndexPage = () => {
    const lang = 'en'

    const {seo} = useStaticQuery(graphql`
        {
            seo: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                seo {
                    title
                    description
                }
            }
        }
    `)

    return (
        <Layout lang={lang} hiddenFooter>
            <Seo
                title={seo && seo.seo && seo.seo.title ? seo.seo.title : ''}
                description={seo && seo.seo && seo.seo.description ? seo.seo.description : ''}
            />

            <Main lang={lang} />
        </Layout>
    )
}

export default IndexPage
